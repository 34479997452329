<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
    >
      <v-icon>{{ snackbar.icon }}</v-icon>
      <span class="white--text ml-3">{{ snackbar.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="snackbar.confirmationBtn"
          color="secondary"
          text
          @click="snackbar.confirm()"
        >
          Confirmar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="800">
      <ClientsForm
        :client="client"
        :isNew="isNew"
        @close="dialog = false"
        @update="updateClient"
        @create="createClient"
      />
    </v-dialog>
    <v-dialog v-model="viewMoreDialog" persistent max-width="1200">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="viewMoreDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ currentClient.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item.value"
              @click="currentComponent = item.value"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.value">
              <KeepAlive>
                <component
                  :is="currentComponent"
                  :currentClient="currentClient"
                  @close="viewMoreDialog = false"
                  @update="updateViewMore"

                  @updateLogo="updateLogo"
                  :isLoadingLogo="isLoadingLogo"

                  @updateLogoHorizontal="updateLogoHorizontal"
                  :isLoadingLogoHorizontal="isLoadingLogoHorizontal"

                  @updateLogoWhite="updateLogoWhite"
                  :isLoadingLogoWhite="isLoadingLogoWhite"

                  @updateLogoBlack="updateLogoBlack"
                  :isLoadingLogoBlack="isLoadingLogoBlack"

                  @updateWaterMark="updateWaterMark"
                  :isLoadingWaterMark="isLoadingWaterMark"
                />
              </KeepAlive>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Table
      :data="data.items ? data : getSystemClients()"
      @edit="editClient"
      @delete="deleteClient"
      @new="newClient"
      @viewMore="viewMore"
    />
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import("@/components/data-table/Table"),
    ClientsForm: () => import("./ClientsForm"),
    MainData: () => import("./view-more-forms/MainData"),
    Logos: () => import("./view-more-forms/Logos"),
    AboutUs: () => import("./view-more-forms/AboutUs"),
  },
  data: () => ({
    dialog: false,
    isNew: false,
    viewMoreDialog: false,
    client: {},
    currentClient: {
      name: null,
    },
    tab: null,
    isLoadingLogo: false,
    isLoadingLogoHorizontal: false,
    isLoadingLogoWhite: false,
    isLoadingLogoBlack: false,
    isLoadingWaterMark: false,
    currentComponent: "main-data",
    items: [
      { title: "Dados Principais", value: "main-data" },
      { title: "Logos e Marca D'água", value: "logos" },
      { title: "Sobre Nós", value: "about-us" },
    ],
    data: {
      items: [],
      isLoading: true,
      headers: [
        { text: "Nome", value: "name" },
        { text: "Status", value: "status", align: "center" },
        { text: "CPF/CNPJ", value: "cpf_cnpj" },
        { text: "Criado em", value: "created_at_formated" },
        { text: "Ações", value: "actions", align: "center" },
      ],
    },
    snackbar: {
      color: "success",
      show: false,
      icon: "mdi-check-circle",
      confirmationBtn: false,
      confirm: Function,
      negate: Function,
      text: "",
      timeout: 2000,
    },
  }),
  methods: {
    /*
     ** Métodos http
     */
    getSystemClients() {
      this.$api
        .get("systemclients")
        .then((res) => {
          this.data.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.data.isLoading = false;
        });
    },
    createClient() {
      this.$api
        .post("systemclients", this.client)
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Cliente cadastrado com sucesso",
            icon: "mdi-check-circle",
          };
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    deleteClient(item) {
      this.snackbar = {
        show: true,
        color: "warning",
        text: "Deseja excluir este cliente?",
        icon: "mdi-alert",
        confirmationBtn: true,
        timeout: -1,
        confirm: () => {
          this.$api
            .delete(`systemclients/${item.id}`)
            .then(() => {
              this.snackbar = {
                show: true,
                color: "success",
                text: "Cliente excluído com sucesso",
                icon: "mdi-check-circle",
                timeout: 2000,
              };
              this.getSystemClients();
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                color: "error",
                text: "Ops! Ocorreu um erro",
                icon: "mdi-alert-octagon",
                timeout: 2000,
              };
            });
        },
        negate: () => {
          this.snackbar = {};
        },
      };
    },
    updateClient() {
      this.$api
        .patch(`systemclients/${this.client.id}`, this.client)
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Cliente atualizado com sucesso",
            icon: "mdi-check-circle",
          };
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateViewMore() {
      console.log(this.currentClient);
      this.$api
        .patch(`systemclients/${this.currentClient.id}`, this.currentClient)
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Cliente atualizado com sucesso",
            icon: "mdi-check-circle",
          };
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateLogo() {
      this.isLoadingLogo = true;
      const formData = new FormData();
      const clientKeys = Object.keys(this.currentClient);
      const clientValues = Object.values(this.currentClient);

      for (let i = 0; i < clientKeys.length; i++) {
        if (clientValues[i] !== null) {
          formData.append(clientKeys[i], clientValues[i]);
        }
      }
      this.$api
        .post("uploads/client/images/logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Logo atualizada com sucesso",
            icon: "mdi-check-circle",
          };
          this.isLoadingLogo = false;
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateLogoHorizontal() {
      this.isLoadingLogoHorizontal = true;
      const formData = new FormData();
      const clientKeys = Object.keys(this.currentClient);
      const clientValues = Object.values(this.currentClient);

      for (let i = 0; i < clientKeys.length; i++) {
        if (clientValues[i] !== null) {
          formData.append(clientKeys[i], clientValues[i]);
        }
      }
      this.$api
        .post("uploads/client/images/logo_horizontal", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Logo Horizontal atualizada com sucesso",
            icon: "mdi-check-circle",
          };
          this.isLoadingLogoHorizontal = false;
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateLogoWhite() {
      this.isLoadingLogoWhite = true;
      const formData = new FormData();
      const clientKeys = Object.keys(this.currentClient);
      const clientValues = Object.values(this.currentClient);

      for (let i = 0; i < clientKeys.length; i++) {
        if (clientValues[i] !== null) {
          formData.append(clientKeys[i], clientValues[i]);
        }
      }
      this.$api
        .post("uploads/client/images/logo_white", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Logo Branca atualizada com sucesso",
            icon: "mdi-check-circle",
          };
          this.isLoadingLogoWhite = false;
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateLogoBlack() {
      this.isLoadingLogoBlack = true;
      const formData = new FormData();
      const clientKeys = Object.keys(this.currentClient);
      const clientValues = Object.values(this.currentClient);

      for (let i = 0; i < clientKeys.length; i++) {
        if (clientValues[i] !== null) {
          formData.append(clientKeys[i], clientValues[i]);
        }
      }
      this.$api
        .post("uploads/client/images/logo_black", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Logo Preta atualizada com sucesso",
            icon: "mdi-check-circle",
          };
          this.isLoadingLogoBlack = false;
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    updateWaterMark() {
      this.isLoadingWaterMark = true;
      const formData = new FormData();
      const clientKeys = Object.keys(this.currentClient);
      const clientValues = Object.values(this.currentClient);

      for (let i = 0; i < clientKeys.length; i++) {
        if (clientValues[i] !== null) {
          formData.append(clientKeys[i], clientValues[i]);
        }
      }
      this.$api
        .post("uploads/client/images/water_mark", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.snackbar = {
            show: true,
            color: "success",
            text: "Marca D'Água atualizada com sucesso",
            icon: "mdi-check-circle",
          };
          this.isLoadingWaterMark = false;
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Ops! Ocorreu um erro",
            icon: "mdi-alert-octagon",
          };
        })
        .finally(() => {
          this.getSystemClients();
        });
    },
    viewMore(item) {
      this.viewMoreDialog = true;
      this.currentClient = item;
      console.log(item);
    },

    /*
     ** Ouvindo eventos da tabela (novo, editar, excluir)
     */
    newClient() {
      this.dialog = true;
      this.isNew = true;
      this.client = {
        status: true,

        name: "Imobiliária Berger",
        trading_name: "Berger Negócios Imobiliarios",
        cpf_cnpj: null,
        contact: null,
        is_whatsapp: null,
        cep: "81010080",
        address: null,
        number: null,
        district: null,
        state_uf: null,
        city: null,
        site: "imobiliariaberger.com.br",
        logo: null,
        logo_horizontal: null,
        water_mark: null,

        // name: null,
        // trading_name: null,
        // cpf_cnpj: null,
        // contact: null,
        // is_whatsapp: null,
        // cep: null,
        // address: null,
        // number: null,
        // district: null,
        // state_uf: null,
        // city: null,
        // site: null,
        // logo: null,
        // logo_horizontal: null,
        // water_mark: null,
      };
    },
    editClient(item) {
      this.dialog = true;
      this.isNew = false;
      this.$api
        .get(`systemclients/${item.id}`)
        .then((res) => {
          this.client = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getSystemClients();
  },
};
</script>